import React, { useState, useEffect } from 'react';
import { Input, Button, Spinner } from 'reactstrap';
import apply from '../images/apply.svg';
import trash from '../images/trash.svg';
import pencil from '../images/pencil.svg';

const ApplicationEditCard = ({ deleteApp, app, fetchAppStatus, saveApp, isApp = true }) => {
  const [ isEditing, setIsEditing ] = useState(false);
  const [ newApp, setNewApp ] = useState(app);
  const [isValidUrl, setIsValidUrl] = useState(null);

  const checkApp = async (card, isSave) => {
    const hasProtocol = /^http[s]?:\/\//.test(card.url);
    const url = hasProtocol ? card.url : `http://${card.url}`;
    const status = await fetchAppStatus(url);
    if (!isSave) {
      setNewApp({
        ...newApp,
        status,
        modifiedUrl: url,
      });
    }
  };

  useEffect(() => {
    if (isApp) {
      checkApp(app);
    }
  }, [app]);

  const onInputchange = (val) => {
    if (val.target.type === 'url') {
      const isUrlValid = (val.target.value).match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
      const isIpValid = (val.target.value).match(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/);
      if (isUrlValid) {
        setIsValidUrl(isUrlValid);
      } else {
        setIsValidUrl(isIpValid);
      }
    } else {
      setIsValidUrl(true);
    }
    setNewApp({
      ...newApp,
      [val.target.name]: val.target.value,
    });
  };

  const onEdit = () => {
    setIsEditing(true);
    setIsValidUrl(true);
  };

  const onSave = async () => {
    if (!isApp) {
      saveApp({
        id: newApp.id,
        name: newApp.name,
        uid: newApp.uid,
      });
    } else {
      saveApp({
        id: newApp.id,
        name: newApp.name,
        url: newApp.url,
      });
    }
    if (isApp) {
      checkApp(newApp, 'save');
    }
    setIsEditing(false);
  };

  const renderStatus = (status) => {
    if (typeof status === 'boolean') {
      return (
        <div className={`response ${status === true ? 'good' : ''}`}>{status === true ? 'Connected' : 'Disconected'}</div>
      );
    }

    return status
      ? <div className={`response ${status}`}>{status === 'good' ? 'Service Online' : 'Service Unreachable'}</div>
      : <Spinner />;
  };

  const renderFields = ({name, placeholder, value = '', url = '', id, type = 'text'}) => {
    const linkOrBlock = isApp
      ? <a href={url} target="_blank" rel="noreferrer">{value}</a>
      : <div>{value}</div>;
    return (
      isEditing
        ? (
          <Input
            type={type}
            name={name}
            id={id}
            placeholder={placeholder}
            onChange={(val) => onInputchange(val, app)}
            value={value}
          />
        )
        : linkOrBlock
    );
  };

  const pointColorByStatus = (status) => {
    switch (status) {
      case false:
        return 'inprogress';
      case true:
        return 'good';
      default:
        return status;
    }
  };

  const isDisabled = isApp
    ? !(newApp.name && newApp.url && isValidUrl)
    : !(newApp.name && isValidUrl);

  return (
    <div className="application-cards-col" key={newApp.id}>
      <div className="application-cards-item card">
        <div className="application-status">
          {isApp
            ? <a href={newApp.modifiedUrl} target="_blank" rel="noreferrer">{newApp.name.slice(0, 1)}</a>
            : <div>{newApp.name.slice(0, 1)}</div>}
          <div className={`application-status-point ${pointColorByStatus(newApp.status)}`} />
        </div>
        <div className="card-info">
          <div className="card-name">
            {renderFields({
              name: 'name',
              placeholder: 'Application Name',
              value: newApp.name || '',
              url: newApp.modifiedUrl,
              id: 'applicationName',
            })}
          </div>
          <div className="card-url">
            {newApp.uid
              ? (
                renderFields({
                  name: 'uid',
                  placeholder: 'Input UID',
                  value: newApp.uid,
                  id: `applicationUrl${newApp.id}`,
                })
              )
              : (
                renderFields({
                  name: 'url',
                  placeholder: 'https://myappurl.com',
                  type: 'url',
                  value: newApp.url,
                  url: newApp.modifiedUrl,
                  id: `applicationUrl${newApp.id}`,
                })
            )}
          </div>
        </div>
        <div className="card-value">
          {!isEditing && renderStatus(newApp.status)}
        </div>
        <div className="card-actions">
          {
            isEditing
            ? (
              <Button
                color="link"
                onClick={onSave}
                disabled={isDisabled}
              >
                <img src={apply} alt="apply" />
              </Button>
            )
            : (
              <Button color="link" onClick={() => onEdit(newApp.id)}>
                <img src={pencil} alt="pencil" />
              </Button>
            )
          }
          <Button color="link" onClick={() => deleteApp(newApp.id)}>
            <img src={trash} alt="trash" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApplicationEditCard;
