import React, { useState } from 'react';
import apiClient from "../../gears/apiClient";
import { Field, Form, Formik } from "formik";
import { Col, FormGroup, Label, Row } from "reactstrap";
import Button from "../Button/Button";
import { toastr } from "react-redux-toastr";
import * as Yup from "yup";
import styles from './announcements.module.scss';
import { TargetEnum } from "../../helpers/announcements_helper";

function AnnouncementsSection() {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const announcementSchema = Yup.object().shape({
		title: Yup.string().required('Field is required'),
		description: Yup.string().required('Field is required'),
	});

	const createAnnouncement = async (title, description, target = TargetEnum.EXTENSION) => {
		try {
			await apiClient.post('/announcement', {
				title,
				description,
				target
			});
			toastr.success('The announcement was created successfully!', 'The announcement will soon be sent to all Extension users.');
		} catch (e) {
			toastr.error('Something went wrong while creating announcements');
		}
	}

	return (
		<div className={styles.announcements}>
			<div className={styles.title}>Create a new announcement</div>
			<span className={styles.subtitle}>Send an important message to your users. This will appear as a pop-up the next time they log in.</span>
			<Formik
				initialValues={{
					title: '',
					description: '',
				}}
				validationSchema={announcementSchema}
				onSubmit={(values) => {
					createAnnouncement(values.title, values.description);
					setIsSubmitted(true);
				}}
				enableReinitialize
			>
				{({ handleChange, resetForm, values, errors }) => (
					<Form className="form form-helpdesk">
						<Row>
							<Col xl="6">
								<div className="settings-tab-item">
									<Row>
										<Col md={10}>
											<FormGroup>
												<Label htmlFor="title">Title</Label>
												<Field
													id="title"
													className={`form-control ${errors.title ? 'is-invalid' : ''}`}
													name="title"
													onChange={(e) => handleChange(e)}
													disabled={isSubmitted}
												/>
											</FormGroup>
											<FormGroup>
												<Label htmlFor="description">Description</Label>
												<Field
													id="description"
													className={`form-control ${errors.description ? 'is-invalid' : ''}`}
													name="description"
													type="textarea"
													onChange={(e) => handleChange(e)}
													as="textarea"
													disabled={isSubmitted}
												/>
											</FormGroup>
											<div className={styles.note}>
												<strong>Note:</strong> Submitting this form will notify all users via an in-app pop-up. Use it for important, time-sensitive updates.
											</div>
											<div className="form-btn d-flex justify-content-between">
												{isSubmitted && (
													<Button
														type="button"
														generalType="default"
														onClick={() => {
															resetForm();
															setIsSubmitted(false);
														}}
													>
														Create a new announcement
													</Button>
												)}
												{!isSubmitted && (
													<Button
														type="submit"
														generalType="default"
													>
														Submit
													</Button>
												)}
											</div>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
		</div>
	);
}

export default AnnouncementsSection;
