export const ROLES = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'super admin',
  HUBBLE_ADMIN: 'hubble admin',
  ORG_ADMIN: 'org admin',
};

export const isAdmin = (role) => role === ROLES.ADMIN;
export const isOrgAdmin = (role) => role === ROLES.ORG_ADMIN;
export const isSuperAdmin = (role) => role === ROLES.SUPER_ADMIN;
export const isHubbleAdmin = (role) => role === ROLES.HUBBLE_ADMIN;