import React from 'react';
import Button from "../../Button/Button";
import { isAdmin, isOrgAdmin } from '../../../constants/roles';

const AdminsTableColumns = ({ onModalOpen, canAssignRoles }) => {
	const isActive = (value) => value.active === true;

	const doesNotAllowOrgs = (original) => {
		return !(isAdmin(original.role) || isOrgAdmin(original.role)) || !isActive(original);
	}

	const doesNotAllowUpgrade = (original) => {
		return !(isAdmin(original.role) || isOrgAdmin(original.role));
	}

	const columns = [
		{
			Header: 'Guid',
			accessor: 'guid',
		},
		{
			Header: 'First name',
			accessor: 'first_name',
		},
		{
			Header: 'Last name',
			accessor: 'last_name',
		},
		{
			Header: 'Email',
			accessor: 'email',
		},
		{
			Header: 'Actions',
			accessor: 'id',
			Cell: ({value, row: { original }}) => (
				<div className="actions">
					{canAssignRoles && (
						<Button
							generalType="default"
							className="mr-2"
							type="button"
							onClick={() => onModalOpen(value, 'super admin')}
							disabled={doesNotAllowUpgrade(original)}
							title="Upgrade to Super Admin"
						>
							<i className="mdi mdi-account-tie" />
						</Button>
					)}
					<Button
						generalType="default"
						type="button"
						onClick={() => onModalOpen(value, 'orgs')}
						disabled={doesNotAllowOrgs(original)}
						title="Organizations"
					>
						<i className="mdi mdi-account-multiple" />
					</Button>
					<Button
						generalType="default"
						className="ml-2"
						type="button"
						onClick={() => onModalOpen(value, 'edit')}
						disabled={!isActive(original)}
						title="Edit"
					>
						<i className="mdi mdi-pencil" />
					</Button>
					<Button
						generalType="default"
						className="ml-2"
						type="button"
						onClick={() => onModalOpen(value, 'delete')}
						disabled={!isActive(original)}
						title="Delete"
					>
						<i className="mdi mdi-delete" />
					</Button>
				</div>
			),
		},
	];
	
	return columns;
};

export default AdminsTableColumns;
