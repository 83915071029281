import * as type from './actionTypes';

export const fetchAdmins = (data) => {
  return {
    type: type.FETCH_ADMINS,
    payload: { data },
  };
};

export const fetchAdminsSuccess = (payload) => {
  return {
    type: type.FETCH_ADMINS_SUCCESS,
    payload,
  };
};

export const fetchAdminsApiError = (error) => {
  return {
    type: type.FETCH_ADMINS_API_ERROR,
    payload: error,
  };
};

export const deleteAdmin = (id, payload) => {
  return {
    type: type.DELETE_ADMIN,
    id,
    payload,
  };
};

export const deleteAdminSuccess = (payload) => {
  return {
    type: type.DELETE_ADMIN_SUCCESS,
    payload,
  };
};

export const deleteAdminError = (error) => {
  return {
    type: type.DELETE_ADMIN_ERROR,
    payload: error,
  };
};

export const updateAdmin = (id, payload, callback) => {
  return {
    type: type.UPDATE_ADMIN_REQUEST,
    id,
    payload,
    callback,
  };
};

export const updateAdminSuccess = (payload) => {
  return {
    type: type.UPDATE_ADMIN_SUCCESS,
    payload,
  };
};

export const updateAdminError = (error) => {
  return {
    type: type.UPDATE_ADMIN_ERROR,
    payload: error,
  };
};

export const setSuperAdmin = (id, payload, callback) => {
  return {
    type: type.SET_SUPER_ADMIN,
    id,
    callback,
  };
};

export const setSuperAdminSuccess = (payload) => {
  return {
    type: type.SET_SUPER_ADMIN_SUCCESS,
    payload,
  };
};

export const setSuperAdminError = (error) => {
  return {
    type: type.SET_SUPER_ADMIN_ERROR,
    payload: error,
  };
};
