import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { getMyProfile } from '../../store/profile/actions';
import { useDispatch, useSelector } from "react-redux";
import ProfileMenu from '../ProfileMenu';
import HeaderInvite from '../HeaderInvite';
import logoIcon from '../../images/logo-icon.svg';
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import Announcements from '../Announcements/Announcements';

const TopBar = ({
    toggleModal,
    openOnboardingModal,
    trial,
    isTrialClosed,
    isSidebarOpen,
  }) => {
  const { companyLogo, isWebTestEnabled, isActivePlan, ownerSetupStep } = useSelector((state) => state.Company);
  const dispatch = useDispatch();
  const { data: profile } = useSelector((state) => state.Profile);
  const [hasWebTest] = useState(isWebTestEnabled);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const history = useHistory();
  // TODO : Hide following the ticket HUB-1712 once is not need any more remove all commented code
  // const match = useRouteMatch();
  // const isDemoUser = match?.params?.id?.includes('demo');
  // const isButtonDisabled = !isActivePlan && ownerSetupStep > 5;

  useEffect(() => {
    if (!profile) {
      dispatch(getMyProfile(null, history));
    }
  }, [profile, getMyProfile, history]);

  return (
    <header
      id="page-topbar"
      className={`header${trial && !isTrialClosed ? ' header_top' : ''}`}
    >
      <div className="navbar-header">
        <div className="d-flex">
          <div className={`navbar-brand-box${isSidebarOpen ? '' : ' closed'}`}>
            <Link to="/admin/overview" className={`logo logo-dark${isSidebarOpen ? '' : ' closed'}`}>
              <span className="logo-wrapper logo-sm">
                <span className="logo-text">Hubble</span>
                <img src={logoIcon} alt="" height="22" />
              </span>
              <span className="logo-wrapper logo-lg">
                <span className="logo-text">Hubble</span>
                <img src={logoIcon} alt="" height="16" />
              </span>
            </Link>
          </div>
        </div>

        <div className="menu">
          <div className="d-flex d-push-right menu-right">
            {/*{!isDemoUser && (*/}
            {/*  <button*/}
            {/*    type="button"*/}
            {/*    className="btn btn-popup"*/}
            {/*    onClick={() => history.push("/admin/first-test")}*/}
            {/*    disabled={isButtonDisabled}*/}
            {/*  >*/}
            {/*    Test Now*/}
            {/*  </button>*/}
            {/*)}*/}
            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="btn btn-popup"*/}
            {/*  onClick={() => window.open('https://www.hubbleiq.com/contact', '_blank')}*/}
            {/*  disabled={isButtonDisabled}*/}
            {/*>*/}
            {/*  Contact Sales*/}
            {/*</button>*/}
            <HeaderInvite toggleModal={toggleModal}/>
            <Announcements
              isOpen={isNotificationsOpen}
              setIsOpen={() => setIsNotificationsOpen(prevState => !prevState)}
            />
            <ProfileMenu
              toggleModal={toggleModal}
              user={profile}
              hasWebTest={hasWebTest}
              companyLogo={companyLogo}
            />
          </div>
        </div>
      </div>
    </header>
  );
};


export default TopBar;
